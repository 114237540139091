import React from 'react'
import { Button } from '../../components/ui/button'
import { z } from 'zod'
import { Project, projects } from './projects'
import { Icon } from '../../components/ui/icon'

const TopLeft = ({ content }: any) => {
	if (content === '01.') {
		return (
			<div className="absolute left-2 top-2">
				<p className=" flex gap-1 ">
					<span className="text-body-md font-semibold">SELECTED</span>
					<span className=" font-serif text-body-md italic">projects</span>
				</p>
				{/* <p className=" text-2xl italic">rubua</p> */}
			</div>
		)
	}
}

const TopRight = () => {
	return (
		<div className="absolute right-2 top-2 flex">
			<Icon name="link-2" />
		</div>
	)
}

const BottomLeft = ({ content }: any) => {
	return (
		<div className="absolute bottom-2 right-2 flex flex-col text-left">
			<div className="">
				<p className="text-body-xs">{content.services}</p>
			</div>
			{/* Other content */}
		</div>
	)
}

const Headline = ({ content }: any) => {
	return (
		<div className="flex h-full items-center justify-center p-8 md:p-0">
			<div className="m-auto my-8 flex max-w-md text-justify text-body-md font-normal leading-5 tracking-normal sm:max-w-md md:my-0 md:max-w-lg md:text-body-xl lg:max-w-sm lg:text-body-md xl:max-w-xl xl:text-body-xl">
				<p>
					<sup className="mr-1 text-body-sm  ">{content.number}</sup>
					<span className="font-serif font-semibold">{content.title}</span>
				</p>
			</div>
		</div>
	)
}

interface CardProps {
	project: Project
}

function Card({ project }: CardProps) {
	return (
		<section
			id="work"
			className={` col-span-2 w-full p-4 md:h-96 lg:col-span-1 lg:h-full ${project.classes}`}
		>
			<div className="relative h-full border border-primary ">
				<Headline content={project} />
				<TopLeft content={project.number} />
				<TopRight />
				<BottomLeft content={project} />
			</div>
		</section>
	)
}

function Work() {
	return (
		<section className="min-w-full lg:h-screen ">
			<div className="grid h-full grid-cols-2 gap-0">
				{projects.map((project: Project, index: number) => {
					return <Card key={index} project={project} />
				})}
			</div>
		</section>
	)
}

export default Work
