import { Spacer } from '../../components/spacer'
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../../components/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '../../components/ui/input'
import { Button } from '../../components/ui/button'
import { z } from 'zod'
import { Textarea } from '../../components/ui/textarea'
import { toast } from 'sonner'
import { supabase } from '../../utils/superbase'
import { useState } from 'react'
import { Spinner } from '../../components/spinner'
import { Toaster } from '@/components/toaster'

const ContactFormSchema = z.object({
	name: z.string(),
	email: z.string().email({
		message:
			'To ensure I can respond to your message, please provide a valid email address.',
	}),
	subject: z.string(),
	message: z.string(),
})

const ContactForm = () => {
	const [loading, setLoading] = useState(false)
	const form = useForm<z.infer<typeof ContactFormSchema>>({
		resolver: zodResolver(ContactFormSchema),
		defaultValues: {
			name: '',
			email: '',
			subject: '',
			message: '',
		},
	})

	async function onSubmit(values: z.infer<typeof ContactFormSchema>) {
		try {
			setLoading(true)
			const { data: response, error } = await supabase.functions.invoke(
				'resend',
				{
					body: values,
				},
			)
			if (response) {
				setLoading(false)
				form.reset()
				toast.success(
					'Thank you for reaching out.Your message has been sent successfully',
					// {
					// 	className: 'bg-primary text-secondary border-secondary ',
					// 	description: 'My description',
					// 	duration: 5000,
					// 	// icon: <MyIcon />,
					// },
				)
			}
			if (error) {
				setLoading(false)
				toast.error(
					'Sorry,Failed to send your message.Please try again!',
					//  {
					// 	className: 'bg-primary text-destructive border-destructive ',
					// 	description: 'My description',
					// 	duration: 5000,
					// 	// icon: <MyIcon />,
					// }
				)
			}
		} catch (error) {
			setLoading(false)
			toast.error('Sorry,Failed to send your message.Please try again!')
		}
	}

	return (
		<div>
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="m-4 space-y-8 md:m-0"
				>
					<div className=" text-left">
						<Spacer size="4xs" />
						<div className="flex flex-col gap-2 md:gap-0">
							<div className="w-64">
								<FormLabel>Your Name</FormLabel>
							</div>
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Input placeholder="Han Solo" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Spacer size="4xs" />
						<div className="flex flex-col ">
							<div className="w-64">
								<FormLabel>Email</FormLabel>
							</div>
							<FormField
								control={form.control}
								name="email"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Input placeholder="hs@mfalcon.com" {...field} />
										</FormControl>
										{/* <FormDescription>
											To ensure I can respond to your message, please provide a
											valid email address.
										</FormDescription> */}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Spacer size="4xs" />
						<div className="flex flex-col ">
							<div className="w-64">
								<FormLabel>Subject</FormLabel>
							</div>
							<FormField
								control={form.control}
								name="subject"
								render={({ field }) => (
									<FormItem className="flex flex-col">
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
										{/* <FormDescription>
        This is the parent that will be used in the dashboard.
      </FormDescription> */}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Spacer size="4xs" />
						<div className="flex flex-col ">
							<div className="w-64">
								<FormLabel>Message</FormLabel>
							</div>
							<FormField
								control={form.control}
								name="message"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Textarea {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Spacer size="3xs" />
						<div className="flex max-w-xl justify-end">
							<Button variant="outline" size="sm" type="submit">
								{loading && <Spinner showSpinner={loading} />}
								Submit
							</Button>
						</div>
					</div>
				</form>
			</Form>
		</div>
	)
}

export default ContactForm
