import * as React from 'react'

import { cn } from '../../utils/misc'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				autoComplete="off"
				type={type}
				className={cn(
					'flex h-10 w-full rounded-none  border border-secondary bg-transparent px-3 py-2 text-sm text-secondary ring-offset-secondary file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-accent/40 focus:bg-transparent focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-1 focus-visible:ring-offset-secondary disabled:cursor-not-allowed disabled:opacity-50',
					//  aria-[invalid]:border-input-invalid
					className,
				)}
				ref={ref}
				{...props}
			/>
		)
	},
)
Input.displayName = 'Input'

export { Input }
