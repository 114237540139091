import { z } from "zod";

export const ServiceSchema = z.object({
  title: z.string(),
  classes: z.string(),
  paragraphs: z.array(
    z.object({
      content: z.string(),
      style: z.object({
        // Define any style options here
        fontSize: z.string().optional(),
        fontWeight: z.string().optional(),
        // Add more style properties as needed
      }).optional(),
    }),
  ),
});

export type Service = z.infer<typeof ServiceSchema>;

export const services: Service[] = [
  {
    title: "Solid Foundations",
    classes: "bg-secondary text-primary",
    paragraphs: [
      {
        content:
          "Web Development: I am proficient in HTML, CSS, and JavaScript, the cornerstones of web development. This ensures I can create the core structure, style, and interactivity for your website.",
      },
      {
        content:
          "Responsive Design: Your website needs to look great and function flawlessly on all devices, from desktops to smartphones. I will design and develop a website that adapts seamlessly to any screen size.",
      },
    ],
  },
  {
    title: "User-Centered Design",
    classes: "bg-accent text-primary  ",
    paragraphs: [
      {
        content:
          "Beautiful Interfaces: I will craft a user interface (UI) that is not only aesthetically pleasing but also intuitive and easy to navigate. Your website will be a joy to use for your visitors.",
      },
      {
        content:
          "Seamless User Experience (UX): Going beyond aesthetics, I will consider the entire user journey, creating a website that is not just visually appealing but also user-friendly and achieves your goals.",
      },
    ],
  },
  {
    title: "Advanced Features & Functionality",
    classes: "bg-accent text-primary  ",
    paragraphs: [
      {
        content:
          "Modern Frameworks & Libraries: I leverage powerful JavaScript frameworks and libraries like React, Nextjs, or Remix to streamline development and create complex interactive features that enhance your website's capabilities.",
      },
      {
        content:
          "API Integration: The web is a connected world! I can integrate APIs to allow your website to communicate with other applications and data sources, unlocking new functionalities.",
      },
    ],
  },
  {
    title: "Integrations & Customization",
    classes: "bg-secondary text-primary  ",
    paragraphs: [
      {
        content:
          "Headless CMS Integration: Beyond traditional CMS platforms, I can integrate your website with a modern JavaScript-based Headless CMS like Strapi,Sanity and others. This allows for greater flexibility and scalability for your content management needs, while I handle the seamless integration into your frontend.",
      },
      {
        content:
          "E-commerce Payment Gateway Integration: For online stores, I can securely integrate popular payment gateways like Stripe, PayPal, or MPesa into your checkout process. This ensures a smooth and trusted buying experience for your customers. ",
      },
    ],
  },
];
