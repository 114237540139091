import { z } from 'zod'

export const ProjectsSchema = z.object({
	title: z.string(),
	classes: z.string(),
	services: z.string(),
	number: z.string(),
	url: z.string(),
})

export type Project = z.infer<typeof ProjectsSchema>

export const projects: Project[] = [
	{
		title: 'SCHOOL TRANSPORT',
		number: '01.',
		url: '',
		classes: 'bg-secondary text-primary',
		services: 'Design,Development,Dashboard',
	},
	{
		title: 'NTSA',
		number: '02.',
		url: '',
		classes: 'bg-accent text-primary  ',
		services: 'Dashboards,Micro-frontends,Auth,APIs',
	},
	{
		title: 'DIL/MOBITILL',
		number: '03.',
		url: '',
		classes: 'bg-accent text-primary  ',
		services: 'Websites,Headless CMS,SEO',
	},
	{
		title: 'MOBITILL EPESI',
		number: '04.',
		url: '',
		classes: 'bg-secondary text-primary  ',
		services: 'Design,Development,Maintenance',
	},
]
