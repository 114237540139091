import LandingPage from '../LandingPage'
import Services from '../Services'
import Work from '../Work'
import About from '../About'
import Contact from '../Contact'

const Home = () => {
	return (
		<div className="h-full w-full overflow-x-hidden">
			<LandingPage />
			<Services />
			<Work />
			<About />
			<Contact />
		</div>
	)
}

export default Home
