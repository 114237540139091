import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './features/Home'
import { Toaster } from './components/toaster'

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="" element={<Home />} />
					{/* <Route path="login" element={<Login />} /> */}
					{/* <Route path="signup" element={<SignUp />} /> */}
				</Routes>
			</BrowserRouter>
			<Toaster />
		</div>
	)
}

export default App
