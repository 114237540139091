import React, { useEffect, useRef } from 'react'
import { Service } from './services'

function ServicesAnimation(props: any) {
	const { services } = props

	const duplicatedServices = [...services,...services,...services,...services];


	return (
		<div className=" flex h-1/6 w-full justify-center bg-primary  align-middle font-serif text-secondary">
			<div className=" my-auto flex h-2/4 w-full justify-center border border-x-0 border-y-4 border-secondary align-middle">
				<div className=" my-auto inline-flex w-fit animate-animate-service overflow:hidden flex-nowrap gap-2 whitespace-nowrap text-secondary">
					{duplicatedServices.map((service: Service, index: number) => {
						return (
							<div
								key={index}
								className=" flex min-w-fit justify-center gap-2 align-middle"
							>
								<div className="min-w-fit">
									<p className="font-serif text-body-md italic lg:text-body-lg ">
										{service.title}
									</p>
								</div>
								<div className="my-auto h-2 w-2 rounded-[50%] bg-secondary lg:h-3 lg:w-3"></div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ServicesAnimation
