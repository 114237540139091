import React from 'react'

const TopLeft = () => {
	return (
		<div className="absolute left-2 top-2">
			<p className=" flex gap-1 text-primary ">
				<span className="text-body-md font-semibold">ABOUT</span>
				<span className=" font-serif text-body-md italic">me</span>
			</p>
			{/* <p className=" text-2xl italic">rubua</p> */}
		</div>
	)
}

const About = () => {
	return ( 
		<section id='about' className="relative w-full bg-primary/10 px-4 md:h-screen">
			<TopLeft />

			<div className="m-auto flex h-full w-full flex-wrap py-10">
				<div className="flex h-full w-full justify-center align-middle md:w-1/2">
					<div className="m-auto max-w-sm border-2 border-secondary py-10 text-left">
						<p className="font-serif text-body-xl text-primary ">
							I am committed to crafting exceptional digital experiences that
							blend form and function seamlessly. With a strong foundation in
							development and an eye for design, I build user-friendly
							interfaces that create value for your brand.
						</p>
					</div>
				</div>
				<div className=" flex h-full w-full justify-center align-middle md:w-1/2">
					<div className=" m-auto flex  max-w-sm flex-col gap-3 py-10 text-left">
						<p className="  text-body-sm text-primary ">
							As a web developer and designer, I bring a robust skill set to the
							table.I mostly use JavaScript and JS based frameworks and
							libraries notably React and Next.js and a spectrum of other tools
							crucial to my field. Whether you're in search of a dedicated team
							member for employment or a skilled professional for individual
							projects, I'm well-equipped to adapt to your needs. My approach
							centers on fostering collaboration and embracing adaptability,
							traits that make me a valuable asset in any digital venture.
						</p>
						<p className=" text-body-sm text-primary ">
							I am deeply committed to delivering excellence, consistently
							devising creative solutions that breathe life into your ideas and
							transform them into polished digital solutions. My eagerness to
							stay at the forefront of industry trends and best practices
							ensures that your projects benefit from the latest innovations.
							I'm open to exploring employment opportunities and individual
							projects alike, and I eagerly await the chance to discuss how I
							can contribute to your team or help you achieve your digital
							aspirations.
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default About
