import React from 'react'
import { Button } from '../../components/ui/button'
import { services, Service } from './services'
import { z } from 'zod'
import ServicesAnimation from './services-anime'

const TopLeft = ({ content }: any) => {
	if (content === 'Solid Foundations') {
		return (
			<div className="absolute left-2 top-2">
				<p className=" flex gap-1 text-primary ">
					<span className="text-body-md font-semibold">WHAT I</span>
					<span className=" font-serif text-body-md italic">offer</span>
				</p>
				{/* <p className=" text-2xl italic">rubua</p> */}
			</div>
		)
	}
}

const TopRight = () => {
	return (
		<div className="absolute right-2 top-2">
			{/* <p className="text-xl font-normal ">{content}</p> */}
			{/* <p className=" text-2xl italic">rubua</p> */}
		</div>
	)
}

const BottomRight = ({ content }: any) => {
	return (
		<div className="absolute bottom-2 right-2 mt-2">
			<p className="text-sm font-semibold ">{content.toUpperCase()}</p>
			{/* <p className=" text-2xl italic">rubua</p> */}
		</div>
	)
}

// Define Zod schema for a paragraph
const ParagraphSchema = z.object({
	content: z.string(),
	style: z
		.object({
			fontSize: z.string().optional(),
			fontWeight: z.string().optional(),
			// Add more style properties as needed
		})
		.optional(),
})

// Define Zod schema for an array of paragraphs
const ParagraphsSchema = z.array(ParagraphSchema)

// Infer the type of the schema
type Paragraph = z.infer<typeof ParagraphSchema>

interface HeadlineProps {
	paragraphs: Paragraph[]
}

const Headline: React.FC<HeadlineProps> = ({ paragraphs }) => {
	return (
		<div id='services' className="flex h-full flex-col items-center justify-center gap-2 py-8 sm:py-2">
			{paragraphs.map((paragraph, index) => (
				<div key={index} className="flex items-center px-4 md:p-0">
					<div className="m-auto my-2 max-w-md text-left text-body-2xs font-normal leading-5 tracking-normal sm:max-w-md md:my-0 md:max-w-lg md:text-base lg:max-w-sm lg:text-body-xs xl:max-w-xl xl:text-base">
						<p style={paragraph.style}>{paragraph.content}</p>
					</div>
				</div>
			))}
		</div>
	)
}

interface CardProps {
	service: Service
}

function Card({ service }: CardProps) {
	return (
		<section
			className={` col-span-2 w-full p-4 md:h-96 lg:col-span-1 lg:h-full ${service.classes}`}
		>
			<div className=" relative h-full border border-primary ">
				<Headline paragraphs={service.paragraphs} />
				<TopLeft content={service.title} />
				<BottomRight content={service.title} />
				{/* <TopRight />
				<BottomLeft /> */}
			</div>
		</section>
	)
}

function Services() {
	return (
		<section className="min-w-full lg:h-screen ">
			<div className="grid h-5/6 grid-cols-2 gap-0">
				{services.map((service: Service) => {
					return <Card key={service.title} service={service} />
				})}
			</div>
			<ServicesAnimation services={services} />
		</section>
	)
}

export default Services
