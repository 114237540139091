import React from 'react'
import { Input } from '../../components/ui/input'
import { Form, FormLabel } from '../../components/form'
import ContactForm from './contact-form'

const TopLeft = () => {
	return (
		<div className="absolute left-2 top-2">
			<p className=" flex gap-1 text-secondary ">
				<span className="text-body-md  font-semibold">CONTACT</span>
				<span className=" font-serif text-body-md italic">me</span>
			</p>
			{/* <p className=" text-2xl italic">rubua</p> */}
		</div>
	)
}

const Contact = () => {
	return (
		<section id="contact" className="relative h-[720px] w-full bg-primary/10 ">
			<div className="m-auto flex h-full w-full flex-wrap">
				<TopLeft />
				<div className="flex h-full w-full justify-center bg-primary align-middle md:w-1/2">
					<div className="m-auto min-w-[330px] max-w-lg border-2 border-secondary p-4 text-left lg:w-[560px]">
						<ContactForm />
					</div>
				</div>
				<div className=" flex h-full w-full justify-center bg-secondary align-middle md:w-1/2">
					<div className=" m-auto flex  max-w-sm flex-col gap-3 py-10 text-left">
						<p className="text-justify font-serif text-9xl font-extrabold text-primary  ">
							DROP ME A LINE
						</p>
						<div className="mt-8 flex justify-between font-sans text-body-md font-semibold text-primary">
							<a href="https://github.com/arubua" target="_blank">
								GITHUB
							</a>
							<a href="https://www.linkedin.com/in/anthony-rubua/" target="_blank">
								LINKEDIN
							</a>
							<a href="https://twitter.com/rubua" target="_blank">
								TWITTER
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Contact
