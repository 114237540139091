import React from 'react'
import { Button } from '../../components/ui/button'
import HamburgerMenu from './HamburgerMenu'

const TopLeft = () => {
	return (
		<div className="absolute left-2 top-2 flex gap-1 font-serif">
			<p className="text-2xl font-bold ">ANTHONY</p>
			<p className=" text-2xl italic">rubua</p>
		</div>
	)
}

const TopRight = () => {
	return (
		<>
			<div className="absolute right-2 top-2 flex">
				
				<HamburgerMenu />
			</div>
		</>
	)
}

const BottomLeft = () => {
	return (
		<div className="absolute bottom-2 left-2 flex flex-col text-left">
			<div className="">
				<p className="text-base">Design</p>
			</div>
			<div>
				<p className="text-base">Development</p>
			</div>
		</div>
	)
}

const Headline = () => {
	return (
		<div className="flex h-full items-center justify-center">
			<div className="m-auto max-w-3xl font-serif text-h2 font-normal">
				<p>Websites are everywhere.Let's make experiences.</p>
			</div>
		</div>
	)
}

function LandingPage() {
	return (
		<section className="h-screen w-full bg-primary p-4 ">
			<div className="relative h-full border border-secondary text-secondary">
				<Headline />
				<TopLeft />
				<TopRight />
				<BottomLeft />
			</div>
		</section>
	)
}

export default LandingPage
